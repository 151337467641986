<template>
  <div class="container-top">
    <div class="pc">
      <div class="banner" :class="{'bg-dark': !detail.circle}">
        <div class="container">
          <div v-if="detail.circle" class="circle" :style="{background: detail.circle}"></div>
        </div>
        <div class="banner-content">
          <div style="text-align: center">
            <div>
              <div class="h2">{{ detail.title }}</div>
              <div class="body0-medium sub2" style="margin-top:12px">{{ detail.subTitle }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="container">
        <div v-for="(item,idx) in detail.contents" :key="'content-'+idx" style="margin-top:80px">
          <div class="subtitle3 main">{{ item.title }}</div>
          <div v-if="item.content" class="body2 sub2" style="margin-top:16px">{{ item.content }}</div>
          <div v-else>
            <div v-for="(step,stepIdx) in item.step" :key="'step-'+stepIdx" style="padding:48px 0">
              <div class="flex-align">
                <div class="h5 primary">STEP {{ stepIdx+1 }}</div>
                <div class="h6 main" style="margin-left:12px">{{ step.title }}</div>
              </div>
              <div class="body1 sub2" style="margin-top:16px">{{ step.content }}</div>
              <div class="body1 sub4" style="margin-top:12px" v-if="step.ex">{{ step.ex }}</div>
              <img :src="img(step.img)" :alt="step.title" style="width:100%;margin-top:32px">
            </div>
          </div>
        </div>
        <div class="box-link">
          <div>
            <div class="h6">{{ detail.link.title }}</div>
            <div class="subtitle4" style="margin-top:8px">{{ detail.link.desc }}</div>
          </div>
          <button class="button is-primary-light" style="width:242px"
                  @click="routerPush(detail.link.link)">{{ detail.link.button }}</button>
        </div>
      </div>
    </div>

    <div class="mobile">
      <div class="banner" :class="{'bg-dark': !detail.circle}">
        <div class="container">
          <div v-if="detail.circle" class="circle" :style="{background: detail.circle}"></div>
        </div>
        <div class="banner-content">
          <div>
            <div class="h6">{{ detail.title }}</div>
            <div class="body4 sub2" style="margin-top:4px">{{ detail.subTitle }}</div>
          </div>
        </div>
      </div>
      <div class="container">
        <div v-for="(item,idx) in detail.contents" :key="'content-'+idx" style="margin-top:24px">
          <div class="subtitle4 main">{{ item.title }}</div>
          <div v-if="item.content" class="body4 sub2" style="margin-top:4px">{{ item.content }}</div>
          <div v-else>
            <div v-for="(step,stepIdx) in item.step" :key="'step-'+stepIdx" style="padding:24px 0">
              <div class="flex">
                <div class="body4-bold primary">STEP {{ stepIdx+1 }}</div>
                <div class="body4-bold main" style="margin-left:12px">{{ step.title }}</div>
              </div>
              <div class="body5 sub2" style="margin-top:4px">{{ step.content }}</div>
              <div class="body6 sub4" style="margin-top:8px" v-if="step.ex">{{ step.ex }}</div>
              <img :src="img(step.img)" :alt="step.title" style="width:100%;margin-top:16px">
            </div>
          </div>
        </div>
      </div>
      <div class="flex-center" style="margin-top:28px" v-if="detail.link.link!=='/quickbuilder'">
        <button class="button is-primary body5-medium" style="width:170px"
                @click="clickButton(detail.link.link)">{{ detail.link.button }}</button>
      </div>
    </div>

  </div>
</template>

<script>
  export default {
    name: "Guide",
    created() {
      this.detail = this.list[this.$route.query.id];
    },
    watch: {
      $route() {
        this.detail = this.list[this.$route.query.id];
      }
    },
    data() {
      return {
        detail: undefined,
        list: [
          {
            circle: '#7B61FF',
            title: '디자인 마켓 이용 가이드',
            subTitle: '디자이너, 개발자 없이도 손가락 하나로 내 서비스 운영',
            contents: [
              {
                title: '디자인 마켓이란?',
                content: '창업자가 디자인 마켓에서 원하는 디자인 스킨을 선택해 서비스를 런칭하는 솔루션입니다. 최소한의 비용으로 높은 퀄리티의 서비스를 런칭할 수 있도록 전문 디자이너가 제작한 다양한 디자인 스킨을 제공합니다. 디자인 스킨 수정을 원하실 경우 수정 옵션을 선택할 수 있습니다. 결제 후 가이드에 따라 디자인 수정 요청서를 배치된 담당자에게 제출하면 나에게 맞춰 디자인을 수정할 수 있습니다. 월 최소 35만 원의 합리적인 요금제로 나만의 플랫폼 서비스를 런칭해보세요!',
              },
              {
                title: '디자인 마켓 이용 방법 ',
                step: [
                  {
                    title: '디자인 스킨 선택',
                    content: '디자인 마켓에서 내 주문전환 방식에 맞는 디자인 스킨을 선택합니다.',
                    img: 'img_de_step1'
                  },
                  {
                    title: '옵션 선택하기',
                    content: '나의 사업 모델에 적합한 상품 옵션을 선택합니다. ',
                    img: 'img_de_step2'
                  },
                  {
                    title: '부가 서비스 선택 및 결제',
                    content: '선택한 테마의 결제 방식과 부가서비스 이용 여부를 선택하고 결제합니다. 디자인 스킨 수정을 선택하지 않은 고객은 STEP 4,5를 건너뛰셔도 됩니다. ',
                    img: 'img_de_step3'
                  },
                  {
                    title: '담당자 배치와 수정 의견서 작성 (수정 옵션 선택 시)',
                    content: '‘디자인 스킨 + 부분 수정 개발 요청’ 옵션을 선택한 고객의 경우, 결제 후 서비스 신청이 접수되면 담당자가 배치됩니다. 담당자가 제시하는 가이드에 따라 수정 의견서를 작성합니다. ',
                    img: 'img_de_step4'
                  },
                  {
                    title: '디자인 컨펌 (수정 옵션 선택 시)',
                    content: '수정 제안서의 디자인, 개발 요소를 검토합니다. ',
                    img: 'img_de_step5'
                  },
                  {
                    title: '개발 진행',
                    content: '검수가 완료된 디자인 시안을 바탕으로 개발에 착수합니다. ',
                    img: 'img_de_step6'
                  },
                  {
                    title: '내 서비스 신청 완료!',
                    content: '런치팩과의 커뮤니케이션으로 지속적인 업데이트가 가능합니다. ',
                    img: 'img_de_step7'
                  }
                ]
              }
            ],
            link: {
              title: '디자인 마켓을 이용한 서비스 런칭을 원한다면? ',
              desc: '원하는 디자인 스킨을 선택해 나만의 플랫폼 서비스를 런칭해 보세요!',
              button: '디자인 마켓 바로 가기',
              link: 'store'
            }
          },
          {
            circle: '#FF6600',
            title: '퀵빌더 이용 가이드',
            subTitle: '블럭들을 조합해서 1시간 만에 런칭',
            contents: [
              {
                title: '퀵빌더란?',
                content: '창업자가 최소한의 비용으로 빠르게 플랫폼을 창업해보고 시장 가능성을 확인할 수 있도록 제공하는 서비스입니다. 전용 빌더 시스템으로 전문가 없이도 쉽게 디자인 블럭을 조합하고 결제 하면, 1시간 내에 서비스를 런칭할 수 있습니다. 월 최소 28만 원의 요금제로 기존 플랫폼 외주개발비용(평균 2,500만 원)과 비교하여 경제적인 가격으로 비즈니스 워킹이 가능합니다.'
              },
              {
                title: '퀵빌더 이용 방법',
                step: [
                  {
                    title: '퀵빌더 접속',
                    content: '홈의 ‘1시간 내 런칭하기’ 또는 솔루션의  ‘퀵빌더로 바로 런칭하기’를 클릭해 퀵빌더로 이동합니다.',
                    img: 'img_qu_step1'
                  },
                  {
                    title: '서비스 정보 입력',
                    content: '내 서비스 이름과 아이디를 입력합니다.',
                    img: 'img_qu_step2'
                  },
                  {
                    title: '상품 주문 방식 선택',
                    content: '전화주문/예약주문/상품배송주문 등 내 플랫폼의 특성에 따라 파트너가 주문을 받는 방식을 의미합니다 (주문 전환 방식에 대한 자세한 설명은 퀵빌더\n' +
                      '서비스 내에서 확인하실 수 있습니다.)',
                    ex: 'ex) 식자재 배달-상품배송주문 / 병원 예약-예약 주문',
                    img: 'img_qu_step3'
                  },
                  {
                    title: '플러그인 선택',
                    content: '성인인증/ 공지사항 팝업 등 내 플랫폼의 특성에 따른 기능을 추가합니다 (플러그인 기능에 대한 자세한 설명은 퀵빌더 서비스 내에서 확인하실 수 있습니다.)',
                    ex: 'ex) 국산주류판매-성인인증',
                    img: 'img_qu_step4'
                  },
                  {
                    title: '페이지 구성',
                    content: '블럭 템플릿을 이용하여 나만의 페이지를 구성합니다.',
                    img: 'img_qu_step5'
                  },
                  {
                    title: '부가 서비스 선택 및 결제',
                    content: '선택한 상품의 결제 방식과 부가 서비스 이용 여부를 선택하고 결제합니다.',
                    img: 'img_qu_step6'
                  },
                  {
                    title: '내 서비스 신청 완료!',
                    content: '약 1시간 내로 개설이 완료되며, 완료 시 신청자 이메일로 안내드립니다.',
                    img: 'img_qu_step7'
                  },
                ]
              }
            ],
            link: {
              title: '퀵빌더를 통해 한 시간 만에 플랫폼 런칭하기',
              desc: '한 시간 만에 플랫폼 런칭을 원한다면 지금 바로 퀵빌더를 이용해 보세요!',
              button: '퀵빌더 바로 가기',
              link: '/quickbuilder'
            }
          }
        ]
      }
    },
    methods: {
      clickButton(link) {
        if(link === '/quickbuilder' && this.isMobile) {
          this.toast('PC에서만 제공되는 서비스입니다');
        } else {
          this.routerPush(link);
        }
      },
      img(img) {
        return `/static/img/guide/${img}.png`;
      }
    }
  }
</script>

<style lang="stylus" scoped>
  @import '~assets/css/lp_main'
  .banner
    background-color $gray4
    height 320px
    position relative
    overflow hidden
    color $main
  .banner-content
    position absolute
    width $page-width
    left 50%
    transform translateX(-50%)
    display flex
    align-items center
    justify-content center
    height 100%
    z-index 1
  .circle
    opacity 0.15
    filter blur(300px)
    -webkit-backdrop-filter blur(300px)
    backdrop-filter blur(300px)
    position absolute
    top -70px
    left -643px
    width $page-width
    height $page-width
  .box-link
    background linear-gradient(322.3deg, #FF6600 22.51%, #F43B00 100%)
    color white
    display flex
    align-items center
    justify-content space-between
    padding 32px 40px
    border-radius 12px

  @media (max-width:1024px)
    .banner
      height 160px
      padding 28px 0
    .banner-content
      width 100%
      top 0
      text-align center
    .circle
      width 370px
      height 370px
      top -50px
      left -50px
</style>
